import React from 'react';
import Icon from 'components/icon/icon';
import lexemes from 'components/lexemes/bff/lexemes';

type HeaderBarProps = {
    languageSelect?: React.ReactNode;
};

// TODO: move menu separately
function HeaderBar({ languageSelect }: HeaderBarProps) {
    const onOpenMenu = React.useCallback(() => {
        global.document.body.classList.toggle('is-opened-mobile-nav');
        // This is super crappy but need to leave it as is for now
        const selector = global.document.querySelector('.js-mobile-navigation');

        if (selector) {
            selector.classList.toggle('is-active');
        }
    }, []);

    return (
        <div className="header-bar">
            <div className="header-bar__navigation">
                <div className="header-bar__navigation-inner is-new js-header-navigation">
                    <div className="header">
                        <div className="header__inner">
                            <div className="header__logo">
                                <a className="logo" href="/en/" title="HOME">
                                    <Icon isNative={true} name={Icon.Name.Logo} />
                                </a>
                            </div>
                            {languageSelect ? (
                                <div className="header__language-selector trigger-dropdown-list">
                                    {languageSelect}
                                </div>
                            ) : null}
                            <nav className="header__menu" aria-labelledby="main-navigation-label">
                                <div
                                    id="main-navigation-label"
                                    className="a11y-visually-hidden"
                                    aria-hidden="true"
                                >
                                    Main
                                </div>
                                <button
                                    className="header-menu__mobile-switcher js-open-menu js-event-link"
                                    data-seo-name="event"
                                    data-seo-category="homepage_button"
                                    data-seo-action="button_click"
                                    data-seo-label="open_menu"
                                >
                                    <div className="navigation-switcher">
                                        <div className="navigation-switcher__bar"></div>
                                        <div className="navigation-switcher__bar"></div>
                                        <div className="navigation-switcher__bar"></div>
                                    </div>
                                    <span className="a11y-visually-hidden">Open menu</span>
                                </button>
                                <ul className="header-menu" role="navigation" onClick={onOpenMenu}>
                                    <li className="header-menu__item">
                                        <a
                                            className="header-menu__link js-event-link"
                                            href="https://shop.bumble.com/"
                                            data-seo-name="event"
                                            data-seo-category="homepage_button"
                                            data-seo-action="button_click"
                                            data-seo-label="shop"
                                        >
                                            <span className="header-menu__link-text">
                                                {lexemes.lxbffLandingHeaderMenuTheShop}
                                            </span>
                                        </a>
                                    </li>
                                    <li className="header-menu__item header-menu__item--dropdown  is-disabled">
                                        <button
                                            className="header-menu__link"
                                            aria-hidden="true"
                                            tabIndex={-1}
                                        >
                                            <span
                                                className="header-menu__link-text"
                                                id="menu-about"
                                            >
                                                {lexemes.lxbffLandingHeaderMenuTheApp}
                                            </span>
                                        </button>
                                        <div className="header-menu__dropdown">
                                            <ul className="header-dropdown">
                                                <li className="header-dropdown__item header-dropdown__item--bumble">
                                                    <a
                                                        className="header-dropdown__link"
                                                        href="/en/date"
                                                        aria-labelledby="menu-about menu-about-date"
                                                    >
                                                        <span
                                                            className="header-dropdown__link-text"
                                                            id="menu-about-date"
                                                            aria-hidden="true"
                                                        >
                                                            {
                                                                lexemes.lxbffLandingHeaderMenuBumbleDate
                                                            }
                                                        </span>
                                                    </a>
                                                </li>
                                                <li className="header-dropdown__item header-dropdown__item--bff">
                                                    <a
                                                        className="header-dropdown__link"
                                                        href="/en/bff"
                                                        aria-labelledby="menu-about menu-about-bff"
                                                    >
                                                        <span
                                                            className="header-dropdown__link-text"
                                                            id="menu-about-bff"
                                                            aria-hidden="true"
                                                        >
                                                            {
                                                                lexemes.lxbffLandingHeaderMenuBumbleBFF
                                                            }
                                                        </span>
                                                    </a>
                                                </li>
                                                <li className="header-dropdown__item header-dropdown__item--bizz">
                                                    <a
                                                        className="header-dropdown__link"
                                                        href="/en/bizz"
                                                        aria-labelledby="menu-about menu-about-buzz"
                                                    >
                                                        <span
                                                            className="header-dropdown__link-text"
                                                            id="menu-about-bizz"
                                                            aria-hidden="true"
                                                        >
                                                            {
                                                                lexemes.lxbffLandingHeaderMenuBumbleBIZZ
                                                            }
                                                        </span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li className="header-menu__item">
                                        <a
                                            className="header-menu__link js-event-link"
                                            href="https://thebeehive.bumble.com/ambassadors"
                                            data-seo-name="event"
                                            data-seo-category="homepage_button"
                                            data-seo-action="button_click"
                                            data-seo-label="ambassadors"
                                        >
                                            <span className="header-menu__link-text">
                                                {lexemes.lxbffLandingHeaderMenuAmbassadors}
                                            </span>
                                        </a>
                                    </li>
                                    <li className="header-menu__item">
                                        <a
                                            className="header-menu__link js-event-link"
                                            href="https://bumble.events"
                                            data-seo-name="event"
                                            data-seo-category="homepage_button"
                                            data-seo-action="button_click"
                                            data-seo-label="events"
                                        >
                                            <span className="header-menu__link-text">
                                                {lexemes.lxbffLandingHeaderMenuEvents}
                                            </span>
                                        </a>
                                    </li>
                                    <li className="header-menu__item">
                                        <a
                                            className="header-menu__link js-event-link"
                                            href="/en/the-buzz/"
                                            data-seo-name="event"
                                            data-seo-category="homepage_button"
                                            data-seo-action="button_click"
                                            data-seo-label="blog"
                                        >
                                            <span className="header-menu__link-text">
                                                {lexemes.lxbffLandingHeaderMenuTheBuzz}
                                            </span>
                                        </a>
                                    </li>
                                    <li className="header-menu__item">
                                        <a className="header-menu__link" href="/en/about">
                                            <span className="header-menu__link-text">
                                                {lexemes.lxbffLandingHeaderMenuAbout}
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HeaderBar;
