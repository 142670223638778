import Localization from 'lib/localization';
import { SupportedLanguage } from 'lib/lexemes/supported-languages';

export default {
    get lxbffLandingSEOTitle() {
        return Localization.get('bumblesite@bff.landing.seo.title');
    },
    get lxbffLandingSEODescription() {
        return Localization.get('bumblesite@bff.landing.seo.description');
    },
    get lxbffLandingHeaderMenuTheShop() {
        return Localization.get('bumblesite@LAYOUT.HEADER.THE_SHOP');
    },
    get lxbffLandingHeaderMenuTheApp() {
        return Localization.get('bumblesite@LAYOUT.FOOTER.THEAPP');
    },
    get lxbffLandingHeaderMenuAmbassadors() {
        return Localization.get('bumblesite@LAYOUT.HEADER.AMBASSADORS');
    },
    get lxbffLandingHeaderMenuEvents() {
        return Localization.get('bumblesite@LAYOUT.FOOTER.EVENTS');
    },
    get lxbffLandingHeaderMenuTheBuzz() {
        return Localization.get('bumblesite@LAYOUT.HEADER.BEEHIVE');
    },
    get lxbffLandingHeaderMenuAbout() {
        return Localization.get('bumblesite@LAYOUT.HEADER.ABOUT');
    },
    get lxbffLandingHeaderMenuBumbleDate() {
        return Localization.get('bumblesite@LAYOUT.FOOTER.BUMBLE_DATE');
    },
    get lxbffLandingHeaderMenuBumbleBFF() {
        return Localization.get('bumblesite@LAYOUT.FOOTER.BUMBLE_BFF');
    },
    get lxbffLandingHeaderMenuBumbleBIZZ() {
        return Localization.get('bumblesite@LAYOUT.FOOTER.BUMBLE_BIZZ');
    },
    get lxbffLandingSectionOneHeaderMobile() {
        return Localization.get('bumblesite@bff.landing.section.one.header.mobile');
    },
    get lxbffLandingSectionOneHeaderDesktop() {
        return Localization.get('bumblesite@bff.landing.section.one.header.mobile');
    },
    get lxbffLandingSectionOneSubHeader() {
        return Localization.get('bumblesite@bff.landing.section.one.subtitle');
    },
    get lxbffLandingSectionOneCta() {
        return Localization.get('bumblesite@bff.landing.section.one.cta');
    },
    get lxbffLandingSectionTwoHeader() {
        return Localization.get('bumblesite@bff.landing.section.two.column.two.heading');
    },
    get lxbffLandingSectionTwoText() {
        return Localization.get('bumblesite@bff.landing.section.two.column.two.text');
    },
    get lxbffLandingSectionThreeColumnLeftHeader() {
        return Localization.get('bumblesite@bff.landing.section.three.column.left.heading');
    },
    get lxbffLandingSectionThreeColumnLeftCta() {
        return Localization.get('bumblesite@bff.landing.section.three.column.left.cta');
    },
    get lxbffLandingSectionThreeColumnRightTitle() {
        return Localization.get('bumblesite@bff.landing.section.three.column.right.title');
    },
    get lxbffLandingSectionFiveColumnRightTitle() {
        return Localization.get('bumblesite@bff.landing.section.five.column.right.title');
    },
    get lxbffLandingSectionFiveColumnRightCta() {
        return Localization.get('bumblesite@bff.landing.section.five.column.right.cta', {
            text: '@BumbleForFriends',
        });
    },
    get lxbffLandingSocialMediaTitle() {
        return '@BumbleForFriends';
    },
    get lxbffLandingEmailCaptureTitle() {
        return Localization.get('bumblesite@bff.landing.email.capture.title');
    },
    get lxbffLandingEmailCaptureText() {
        return Localization.get('bumblesite@bff.landing.email.capture.text');
    },
    lxbffLandingEmailCaptureFooter({ lang }: { lang: SupportedLanguage }) {
        return (
            Localization.get('bumblesite@bff.landing.email.capture.legal.footer', {
                terms_url: lang
                    ? `https://bumble.com/${lang}/bff/terms`
                    : 'https://bumble.com/bff/terms',
                privacy_url: lang
                    ? `https://bumble.com/${lang}/bff/privacy`
                    : 'https://bumble.com/bff/privacy',
            })
                // @TODO: ask LOC team to fix it
                .replace('className', 'class')
        );
    },
    get lxbffLandingEmailCaptureFieldFirstName() {
        return Localization.get('bumblesite@bff.landing.email.capture.field.firstname');
    },
    get lxbffLandingEmailCaptureFieldLastName() {
        return Localization.get('bumblesite@bff.landing.email.capture.field.lastname');
    },
    get lxbffLandingEmailCaptureFieldEmailErrorLabel() {
        return Localization.get('bumblesite@bff.landing.email.capture.field.email');
    },
    get lxbffLandingEmailCaptureFieldEmail() {
        return Localization.get('bumblesite@bff.landing.email.capture.field.email.error');
    },
    get lxFormSuccessSubmissionText() {
        return Localization.get('bumblesite@bff.landing.email.capture.form.submission.success');
    },
    get lxbffLandingSectionFiveColumnTitle() {
        return Localization.get('bumblesite@bff.landing.section.five.column.title');
    },
    get lxbffLandingStickerOneLabelAlt() {
        return Localization.get('bumblesite@bff.landing.sticker.alt');
    },
    get lxbffLandingEmailCaptureFieldCaptcha() {
        return Localization.get('huggle_site@FORMS/ERRORS/REQUIRED_CAPTCHA');
    },
    get lxbffLandingEmailCaptureFieldCta() {
        return Localization.get('bumblesite@bff.landing.email.capture.field.cta');
    },
    get lxbffLandingTrendAltLabel() {
        return Localization.get('bumblesite@trend-of-the-year-2021.alt');
    },
};
