import * as React from 'react';
import cx from 'classnames';
import { Theme, mapThemeToClassNames } from 'ui/themes';

enum PageSectionBackground {
    NONE = 'NONE',
    PRIMARY = 'PRIMARY',
}

export const mapBackgroundToClassname: Record<PageSectionBackground, string> = {
    [PageSectionBackground.NONE]: '',
    [PageSectionBackground.PRIMARY]: 'page-section--primary',
};

export type PageSectionProps = {
    children?: React.ReactNode;
    theme?: Theme;
    bg?: PageSectionBackground;
    splashScreen?: boolean;
    inner?: boolean;
    isFullWidth?: boolean;
    hasPadding?: boolean;
    hasOnlyTopPadding?: boolean;
    extraClass?: string;
    jsClass?: string;
    isSecondary?: boolean;
    hasFullWidthOnMobile?: boolean;
    hasLimitedWidth?: boolean;
};

function PageSection(props: PageSectionProps) {
    const {
        children,
        theme = Theme.NONE,
        bg = PageSectionBackground.NONE,
        splashScreen,
        inner = true,
        isFullWidth,
        hasPadding,
        hasOnlyTopPadding,
        extraClass,
        jsClass,
        isSecondary,
        hasFullWidthOnMobile,
        hasLimitedWidth,
    } = props;

    const className = cx({
        'page-section': true,
        'page-section--splash-screen': splashScreen,
        'page-section--full-width': isFullWidth,
        'page-section--padded': hasPadding,
        'page-section--padded-only-top': hasOnlyTopPadding,
        'page-section-mobile-full-width': hasFullWidthOnMobile,
        'page__section--secondary': isSecondary,
        'page-section--limited-width': hasLimitedWidth,
        [mapThemeToClassNames[theme]]: true,
        [mapBackgroundToClassname[bg]]: true,
        [extraClass as string]: extraClass,
        [jsClass as string]: jsClass,
    });

    const content = !inner ? children : <div className="page-section__inner">{children}</div>;

    return <section className={className}>{content}</section>;
}

PageSection.Theme = Theme;
PageSection.Background = PageSectionBackground;

export default PageSection;
