import React from 'react';
import Link from 'components/link/link';
import { useRouter } from 'next/router';
import { SUPPORTED_LANGUAGE_LIST, SUPPORTED_LANGUAGE_LIST_ITEM_TYPE } from 'lib/constants';

const INITIAL_LANGUAGE = SUPPORTED_LANGUAGE_LIST[0];
export function LanguageSelector() {
    const router = useRouter();
    const [currentLanguage, setCurrentLanguage] =
        React.useState<SUPPORTED_LANGUAGE_LIST_ITEM_TYPE>(INITIAL_LANGUAGE);

    React.useEffect(() => {
        const languageCode = router.asPath.replace('/', '').replace(router.basePath, '');

        SUPPORTED_LANGUAGE_LIST.forEach((supportedLanguage) => {
            if (supportedLanguage.code === languageCode) {
                setCurrentLanguage(supportedLanguage);

                return;
            }

            return;
        });
    }, [router]);

    return (
        <div className="language-selector">
            <div className="language-selector__inner">
                <label className="language-selector__label" htmlFor="language-selector-toggle">
                    {currentLanguage.name}
                </label>
                <input
                    className="language-selector__trigger"
                    type="checkbox"
                    id="language-selector-toggle"
                />
                <div className="language-selector__dropdown">
                    <div className="dropdown-list">
                        <div className="dropdown-list__inner">
                            {SUPPORTED_LANGUAGE_LIST.map((language) => {
                                if (language.code === currentLanguage.code) {
                                    return (
                                        <span
                                            className="dropdown-list__item is-active"
                                            key={language.code}
                                        >
                                            <span className="dropdown-list__item-title">
                                                {language.name}
                                            </span>
                                        </span>
                                    );
                                }
                                return (
                                    <Link
                                        className="dropdown-list__item"
                                        href={`/${language.code}/bff`}
                                        key={language.code}
                                    >
                                        <span className="dropdown-list__item-title">
                                            {language.name}
                                        </span>
                                    </Link>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
